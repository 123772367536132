import { useTranslation } from "next-i18next";
import { oneOf, string } from "prop-types";
import { useContext } from "react";

import Icon from "~components/Icon";
import { FavouriteItemsContext } from "~contexts/FavouriteItemsContext";
import { useToast } from "~contexts/Toaster";
import classnames from "~utils/classnames";

import classes from "./FavouriteButton.module.scss";

const FavouriteButton = ({ itemId, itemTitle, variant = "default" }) => {
  const { t } = useTranslation();
  const { favouriteItemIds, toggleFavouriteItem } = useContext(
    FavouriteItemsContext,
  );
  const { addToast } = useToast();
  const isFavourite = favouriteItemIds.includes(itemId);

  const onToggleFavouriteClick = (event) => {
    event.preventDefault();
    toggleFavouriteItem({
      id: itemId,
      title: itemTitle,
    });
    if (!isFavourite) {
      addToast("heart-solid", t("toastEventTitle"), t("toastEventDescription"));
    }
  };

  const favouriteClass = classnames(
    classes.favourite,
    classes[variant],
    isFavourite && classes.is_active,
  );

  return (
    <button
      className={favouriteClass}
      onClick={onToggleFavouriteClick}
      title={isFavourite ? t("removeFromFavourites") : t("addToFavourites")}
    >
      <Icon type={isFavourite ? "heart-solid" : "heart-outline"} />
      <div className={classes.balls}>
        <span className={`${classes.ball} ${classes["ball-1"]}`} />
        <span className={`${classes.ball} ${classes["ball-2"]}`} />
        <span className={`${classes.ball} ${classes["ball-3"]}`} />
      </div>
    </button>
  );
};

FavouriteButton.propTypes = {
  itemId: string.isRequired,
  itemTitle: string,
  variant: oneOf(["default", "theme"]),
};

export default FavouriteButton;
